@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rectangle {
  color: #35363a;
  display: flex;
  width: fit-content !important;
  height: fit-content !important;
  padding: 5px;
  margin: 0 5px;
  /* pointer-events: none; */
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  border-radius: 5px;
  border: 1px solid #ececec;
  background: #fff;
  cursor: auto;
}

/* Audio Controls CSS styles to disable controls*/

.custom-audio-controls::-webkit-media-controls-play-button,
.custom-audio-controls::-webkit-media-controls-start-playback-button,
.custom-audio-controls::-webkit-media-controls-pause-button,
.custom-audio-controls::-webkit-media-controls-stop-button,
.custom-audio-controls::-webkit-media-controls-rewind-button,
.custom-audio-controls::-webkit-media-controls-seek-back-button,
.custom-audio-controls::-webkit-media-controls-seek-forward-button,
.custom-audio-controls::-webkit-media-controls-mute-button,
.custom-audio-controls::-webkit-media-controls-volume-slider,
.custom-audio-controls::-webkit-media-controls-fullscreen-button,
.custom-audio-controls::-webkit-media-controls-toggle-closed-captions-button {
  display: none !important;
}

.custom-audio-controls::-webkit-media-controls-timeline {
  display: block !important;
  width: 100%;
}
